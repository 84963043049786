const SESSION_EXPIRATION_TIME = 30 * 60 * 1000 // 30 minutes

interface IEventSessionData {
  sessionId: string
  expiryOn: number // in milliseconds
  // Requirement: If a user logs out, and then there is a log-in with a different user ID then that should change the session_id
  // Solution : persists last valid userId between multiple login logout sessionExpired state, to detect this change
  lastValidUserId?: number
}

const EventSessionStoreName = 'l2_event_session_data'
export const EventSessionStore = {
  get: (): IEventSessionData => {
    const localStorageDataString = localStorage.getItem(EventSessionStoreName) || '{}'
    const data = JSON.parse(localStorageDataString)
    return {
      ...data,
      userId: data.userId ?? undefined,
      sessionId: data.sessionId ?? '',
      expiryOn: data.expiryOn ?? 0,
      pausedOn: data.pausedOn ?? 0,
    }
  },
  set: (data: IEventSessionData) => {
    localStorage.setItem(EventSessionStoreName, JSON.stringify(data))
  },
}

export function getEventSessionId(user?: LumosUser) {
  const { expiryOn, sessionId, lastValidUserId } = EventSessionStore.get()
  const currentUserId: IEventSessionData['lastValidUserId'] = user?.id ?? undefined
  const newExpiryOn = Date.now() + SESSION_EXPIRATION_TIME
  const newSessionId = crypto.randomUUID()
  const currentTime = Date.now()

  if (!sessionId) {
    EventSessionStore.set({
      sessionId: newSessionId,
      expiryOn: newExpiryOn,
      lastValidUserId: currentUserId,
    })
    return newSessionId
  }

  // detect lastValidUserIdChanged, so that we can reset the session
  let lastValidUserIdChanged = false
  let updatedValidUserId = lastValidUserId
  if (currentUserId && lastValidUserId !== currentUserId) {
    lastValidUserIdChanged = true
    updatedValidUserId = currentUserId
  }

  if (lastValidUserIdChanged) {
    // set the lastValidUserId only if currentUserId exists else take lastValidUserId
    // need this information so that we can reset the session if the user is different between mutiple login logout state
    EventSessionStore.set({
      sessionId: newSessionId,
      expiryOn: newExpiryOn,
      lastValidUserId: currentUserId,
    })
    return newSessionId
  }

  if (currentTime > expiryOn) {
    EventSessionStore.set({
      sessionId: newSessionId,
      expiryOn: newExpiryOn,
      lastValidUserId: updatedValidUserId,
    })
    return newSessionId
  }

  EventSessionStore.set({
    sessionId,
    expiryOn: newExpiryOn,
    lastValidUserId: updatedValidUserId,
  })
  return sessionId
}

export function extendEventSession() {
  const eventData = EventSessionStore.get()
  EventSessionStore.set({
    ...eventData,
    expiryOn: Date.now() + SESSION_EXPIRATION_TIME,
  })
}
