import { useCallback } from 'react'

import { MarketingContext } from '~/context/MarketingContext'
import { useInitializeMarketingFramework } from '~/hooks/useInitializeMarketingFramework'

interface MarketingProviderProps {
  children?: React.ReactNode | React.ReactNode[]
}

export enum MarketingEvents {
  ScreenView = 'Screen View',
  WorkoutFinish = 'Workout Finish',
  GameStart = 'Game Start',
  GameFinish = 'Game Finish',
  FitTestStart = 'Fit Test Start',
  FitTestFinish = 'Fit Test Finish',
}

const MarketingProvider: React.FC<MarketingProviderProps> = ({ children }) => {
  const {
    marketingUser,
    handleBrazeAction,
    logCustomMarketingEvent,
    logCardClickEvent,
    logCardImpressionEvent,
    logCardDismissalEvent,
    contentCards,
    setContentCards,
  } = useInitializeMarketingFramework()
  const logMarketingWorkoutComplete = useCallback(
    (gameSlugs: GameSlug[]) => logCustomMarketingEvent(MarketingEvents.WorkoutFinish, { game_slugs: gameSlugs }),
    [logCustomMarketingEvent],
  )

  const logMarketingFitTestStart = useCallback(
    () => logCustomMarketingEvent(MarketingEvents.FitTestStart),
    [logCustomMarketingEvent],
  )

  const logMarketingFitTestFinish = useCallback(
    () => logCustomMarketingEvent(MarketingEvents.FitTestFinish),
    [logCustomMarketingEvent],
  )

  const logMarketingGameStart = useCallback(
    (gameSlug: GameSlug) => logCustomMarketingEvent(MarketingEvents.GameStart, { game_slug: gameSlug }),
    [logCustomMarketingEvent],
  )

  const logMarketingGameFinish = useCallback(
    (gameSlug: GameSlug, score: number, areaSlug: AreaSlug | '') =>
      logCustomMarketingEvent(MarketingEvents.GameFinish, {
        game_slug: gameSlug,
        score,
        game_category: areaSlug,
      }),
    [logCustomMarketingEvent],
  )

  const marketingContext: MarketingContext = {
    marketingUser,
    contentCards,
    setContentCards,
    handleBrazeAction,
    logCustomMarketingEvent,
    logCardClickEvent,
    logCardImpressionEvent,
    logCardDismissalEvent,
    logMarketingWorkoutComplete,
    logMarketingFitTestStart,
    logMarketingFitTestFinish,
    logMarketingGameStart,
    logMarketingGameFinish,
  }

  return <MarketingContext.Provider value={marketingContext}>{children}</MarketingContext.Provider>
}

export default MarketingProvider
