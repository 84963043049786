if (typeof crypto.randomUUID !== 'function') {
  crypto.randomUUID = function (): `${string}-${string}-${string}-${string}-${string}` {
    const randomHex = (length: number): string => {
      return Array.from(crypto.getRandomValues(new Uint8Array(length)))
        .map((byte) => byte.toString(16).padStart(2, '0'))
        .join('')
    }

    const uuid = `222${randomHex(1)}-${randomHex(2)}-${randomHex(2)}-${randomHex(2)}-${randomHex(6)}`
    return uuid as `${string}-${string}-${string}-${string}-${string}`
  }
}
