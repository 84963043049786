import { SnackbarProvider as OriginalSnackbarProvider } from 'notistack'
import { createGlobalStyle } from 'styled-components'

import DetailedInfoSnackbar from '~/components/snackbars/DetailedInfoSnackbar'
import ErrorSnackbar from '~/components/snackbars/ErrorSnackbar'
import SuccessSnackbar from '~/components/snackbars/SuccessSnackbar'
import { ModalStyles } from '~/constants'

interface SnackbarProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  return (
    <>
      {/* SnackBar is not a styled component, it needs containerRoot className to override the style, this will make sure that we have z-index more than Modal */}
      <SnackbarGlobalStyle />
      <OriginalSnackbarProvider
        maxSnack={1}
        classes={{ containerRoot: 'snackbar-container-root-override' }}
        autoHideDuration={8000} // default was 5sec, we are increasing it to 8sec
        // anchorOrigin : setting a default for all snackbars, enqueueSnackbar can override this
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        Components={{
          // register new variant and provide its type in @types/notistack.d.ts
          success: SuccessSnackbar,
          error: ErrorSnackbar,
          detailedInfo: DetailedInfoSnackbar,
        }}
      >
        {children}
      </OriginalSnackbarProvider>
    </>
  )
}

export default SnackbarProvider

// should be more than the z-index of the modal from StyledModalBackground.tsx
const SnackbarGlobalStyle = createGlobalStyle`
  /* !important because of the specificity of CSS by notistack itself. */  
  .snackbar-container-root-override{
    z-index: ${ModalStyles.zIndex + 10} !important;
  }
`
