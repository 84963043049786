/**
 * The useTrackEvents hook is used to track specific events that don't fit into the other tracking hooks.
 * Eg: SignOut, EmailTrainingReminder
 * Think of it as a place where you would want to implement miscellaneous tracking events.
 */
import { useCallback, useContext } from 'react'

import UAParser from 'ua-parser-js'

import { EventsContext } from '~/events/EventsContext'
import { ErrorEventTypesValues, EventClickNamesValues, EventName } from '~/events/eventTypes'
import logger from '~/utils/logger'

interface IEmailTrainingReminderData {
  is_training_reminders: boolean
  training_reminders_days: string[]
  training_reminders_time: string
}

interface IErrorDisplayData {
  errorMessage: string
}

interface IErrorEventData {
  error_type: ErrorEventTypesValues
  error_location: string
  error_message?: string
  error_details?: any
  click_name?: EventClickNamesValues
  game_slug?: string
}

interface IUnsupportedBrowserErrorData {
  userAgentInfo: UAParser.IResult
  isWebGL2Available: boolean
  isHTML5Available: boolean
}

const useTrackEvents = () => {
  const {
    currentScreen,
    clients: { lumosEventApi },
  } = useContext(EventsContext)

  const trackEmailTrainingReminder = useCallback(
    (data: IEmailTrainingReminderData) => {
      lumosEventApi.track(EventName.EmailTrainingRemindersSelect, data)
    },
    [lumosEventApi],
  )

  const trackSignOut = useCallback(() => {
    lumosEventApi.track(EventName.SignOut, {})
  }, [lumosEventApi])

  const trackErrorDisplay = useCallback(
    ({ errorMessage }: IErrorDisplayData) => {
      lumosEventApi.track(EventName.ErrorDisplay, {
        screen_name: currentScreen,
        error_message: errorMessage,
      })
    },
    [lumosEventApi, currentScreen],
  )

  const trackErrorEvent = useCallback(
    ({ error_message, error_details, error_type, click_name, error_location, game_slug }: IErrorEventData) => {
      try {
        lumosEventApi.track(EventName.ErrorOccur, {
          screen_name: currentScreen,
          error_type,
          error_message,
          error_location,
          error_details,
          ...(click_name && { click_name }),
          ...(game_slug && { game_slug }),
        })
      } catch (error) {
        logger.error('useTrackEvents::trackErrorEvent::catch', error)
      }
    },
    [lumosEventApi, currentScreen],
  )

  const trackUnsupportedBrowserError = useCallback(
    ({ userAgentInfo, isWebGL2Available, isHTML5Available }: IUnsupportedBrowserErrorData) => {
      lumosEventApi.track(EventName.UnsupportedBrowserError, {
        screen_name: currentScreen,
        user_agent: userAgentInfo,
        is_webgl2_available: isWebGL2Available,
        is_html5_available: isHTML5Available,
      })
    },
    [lumosEventApi, currentScreen],
  )

  return { trackSignOut, trackEmailTrainingReminder, trackErrorDisplay, trackErrorEvent, trackUnsupportedBrowserError }
}

export default useTrackEvents
