import { useEffect } from 'react'

import { BodyText2Bold } from '@lumoslabs/lumosity-storybook'
import { animated, useSpring } from '@react-spring/web'
import lottie from 'lottie-web'
import styled from 'styled-components'

import { springs } from '~/animation/Loading.animation'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

export const LoadingOrb = ({ showLoadingText }: { showLoadingText?: boolean } = {}) => {
  const showText = showLoadingText ?? true
  const t = useTranslationForNamespace('common')
  const loadingTextAnimation = useSpring(springs.fadeIn())

  useEffect(() => {
    const loadingOrb = lottie.loadAnimation({
      container: document.querySelector('#loading-container') as Element,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/assets/lottieAnimations/loadingOrb.json',
    })
    return () => {
      loadingOrb.destroy()
    }
  }, [])

  return (
    <CenteringContainer>
      <LoadingOrbContainer id='loading-container' />
      <LoadingText as={animated.div} style={loadingTextAnimation}>
        {showText ? t('loadingWithEllipses') : null}
      </LoadingText>
    </CenteringContainer>
  )
}

const PageLoader = () => {
  return (
    <LoadingContainer>
      <LoadingOrb />
    </LoadingContainer>
  )
}

export default PageLoader

const LoadingText = styled(BodyText2Bold)`
  color: ${(props) => props.theme.colors.system.primaryAccent};
  text-align: center;
  margin-top: 24px;
`

const LoadingOrbContainer = styled.div`
  width: 120px;
`

const LoadingContainer = styled.div`
  z-index: 1000;
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CenteringContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
