import { createContext } from 'react'

export interface EventsContextInterface {
  clients: {
    lumosEventApi: {
      track: (eventName: string, payload: { [key: string]: any }) => unknown
    }
    /**
     * example of how same interface can send events to multiple vendors
     * amplitude : {
     *    track : (eventName: string, payload: { [key: string]: any }) => unknown
     * }
     */
  }

  /** as there can only be 1 active current screen at 1 point of time */
  currentScreen: string
  setCurrentScreen: (name: string) => unknown
}

export const EventsContext = createContext<EventsContextInterface>({
  clients: {
    lumosEventApi: {
      track() {
        /** default code needed for context initialization */
      },
    },
  },
  currentScreen: '',
  setCurrentScreen: () => null,
})
