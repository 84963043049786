interface IEventDeviceData {
  device_id: string
}

const EventDeviceStoreName = 'l2_event_device_data'
const EventDeviceStore = {
  get: (): Partial<IEventDeviceData> => {
    const localStorageDatString = localStorage.getItem(EventDeviceStoreName) || '{}'
    return JSON.parse(localStorageDatString)
  },
  set: (data: IEventDeviceData) => {
    localStorage.setItem(EventDeviceStoreName, JSON.stringify(data))
  },
}

export function getEventDeviceId(): IEventDeviceData['device_id'] {
  const storageDeviceData = EventDeviceStore.get()
  const storageDeviceId = storageDeviceData?.device_id ?? undefined

  if (!storageDeviceId) {
    const uuid = crypto.randomUUID()
    EventDeviceStore.set({ device_id: uuid })
    return uuid
  }
  return storageDeviceId
}
