import { useCallback, useEffect, useState } from 'react'

export function useLocalStorageBackedState<T>(
  key: string,
  initialValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setStateBase] = useState<T>(initialValue)

  useEffect(() => {
    const storedValue = localStorage.getItem(key)
    if (storedValue) {
      setStateBase(JSON.parse(storedValue))
    }
  }, [key])

  const setState = useCallback(
    (value: React.SetStateAction<T>) => {
      const newState = value instanceof Function ? value(state) : value
      setStateBase(newState)
      localStorage.setItem(key, JSON.stringify(newState))
    },
    [key, state],
  )

  return [state, setState]
}
