import { forwardRef } from 'react'

import { BodyText2SemiBold, Caption1 } from '@lumoslabs/lumosity-storybook'
import { SnackbarContent, SnackbarKey, SnackbarMessage, VariantOverrides } from 'notistack'
import styled from 'styled-components'

import CloseIcon from '~/images/icons/SystemOutlined/Close.svg'
import { useSnackbar } from '~/libs/notistack'

type DetailedInfoProps = VariantOverrides['detailedInfo']
interface DetailedInfoSnackbarProps extends DetailedInfoProps {
  id: SnackbarKey
  message: SnackbarMessage
}

const DetailedInfoSnackbar = forwardRef<HTMLDivElement, DetailedInfoSnackbarProps>(
  ({ message, id, description }, ref) => {
    const { closeSnackbar } = useSnackbar()
    return (
      <SnackbarContent ref={ref} id={id as string} style={{ display: 'grid' }}>
        <SnackbarContainer>
          <Title as='h1'>{message}</Title>
          <Caption1>{description}</Caption1>
          <CloseIconButton title='close-message' onClick={() => closeSnackbar()} />
        </SnackbarContainer>
      </SnackbarContent>
    )
  },
)

const SnackbarContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, #ffffff -12.81%, #36ccc1 215%);
  padding: 24px;
  padding-top: 40px;
  display: grid;
  max-width: 360px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06), 0px 3px 19px 0px rgba(0, 0, 0, 0.15);
`

const Title = styled(BodyText2SemiBold)`
  margin: 0px;
`

const CloseIconButton = styled(CloseIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.colorTokens.icon['icon-subdued']};
`

export default DetailedInfoSnackbar
