import { useEffect } from 'react'

import { ReactiveVar } from '@apollo/client'
import { useCookies } from 'react-cookie'

import { LUMOSITY_USER_COOKIE } from '~/constants'
import {
  playGameSoundsVar,
  showSideNavVar,
  gameListFilterVar,
  originPathBeforeSubscribeVar,
  promotionVar,
  invitationVar,
} from '~/graphql/reactive-vars'
import { currentUserVar } from '~/graphql/reactive-vars/userVar'
import { parseUser } from '~/utils/loginUtils'

// Map which storage key uses which reactive var
const reactiveVarsByStorageKeys: Record<string, ReactiveVar<any>> = {
  game_sound_on: playGameSoundsVar,
  show_side_nav: showSideNavVar,
  game_list_filter: gameListFilterVar,
  origin_path_before_subscribe: originPathBeforeSubscribeVar,
  promotion: promotionVar,
  invitation: invitationVar,
}

/**
 * Handles changes to persisted client state
 * @param storageEvent Emitted on changes to localStorage
 */
const storageHandler = (storageEvent: StorageEvent) => {
  for (const key in reactiveVarsByStorageKeys) {
    if (storageEvent.key === key) {
      const { newValue } = storageEvent
      const parsedNewValue = JSON.parse(newValue || 'null')
      const reactiveVar = reactiveVarsByStorageKeys[key]
      newValue && reactiveVar(parsedNewValue)
    }
  }
}

const usePersistedClientConfig = (): void => {
  const [{ [LUMOSITY_USER_COOKIE]: lumosityUserCookie }] = useCookies([LUMOSITY_USER_COOKIE])

  useEffect(() => {
    const lastUser = parseUser(lumosityUserCookie) ?? undefined
    currentUserVar(lastUser)

    // reactiveVariables that are persisted must be initialized
    // from localStorage on the client
    // 'null' fallback to satisfy Typescript
    for (const key in reactiveVarsByStorageKeys) {
      const storageValue = JSON.parse(localStorage.getItem(key) ?? 'null')
      const reactiveVar = reactiveVarsByStorageKeys[key]
      storageValue !== null && reactiveVar(storageValue)
    }

    window.addEventListener('storage', storageHandler)
    return () => window.removeEventListener('storage', storageHandler)
  }, [lumosityUserCookie])
}

export default usePersistedClientConfig
