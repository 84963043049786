import { ReactiveVar } from '@apollo/client'

import makeVarPersisted from '~/utils/makeVarPersisted'

export const invitationInitialValue: InvitationVar = {
  invitationId: '',
  hasAcceptedInvite: false,
}

export const invitationVar: ReactiveVar<InvitationVar> = makeVarPersisted<InvitationVar>(
  invitationInitialValue,
  'invitation',
)
