import { useEffect, useState } from 'react'

import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import { LUMOSITY_USER_COOKIE } from '~/constants'
import { currentUserVar } from '~/reactive-vars'
import logger from '~/utils/logger'
import { getBrowserCookieOpts, parseUser } from '~/utils/loginUtils'

const { publicRuntimeConfig } = getConfig()

const authFlagshipUserWithL2 = async (url: string): Promise<string | null> => {
  logger.info('User continuity: Authenticating Flagship user with L2', url)
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    cache: 'no-store',
    body: '{}',
  })
  try {
    const { idToken }: { idToken: string | null } = await response.json()
    if (!idToken) {
      logger.info('User continuity: No Flagship user was detected')
    } else {
      logger.info(`User continuity: Flagship user was detected`)
    }
    return idToken
  } catch (err) {
    if (err instanceof SyntaxError) {
      // Could not parse JSON, likely empty page
      return null
    }
    throw err
  }
}

const useFlagshipUser = () => {
  const router = useRouter()
  const [{ [LUMOSITY_USER_COOKIE]: l2Token }, setCookie] = useCookies([LUMOSITY_USER_COOKIE])
  // LUM-1867 : by default we will wait in flagship, until useEffect make it false,
  // make sure this never becomes true again, once it becomes false
  // doing this will avoid unmounting & mounting of all nested components
  // this result into inconsistent data around the app, like 2 time sending screen view event.
  // this could be treated as useRef, but useRef doesn't trigger re-render, thus we are using useState
  const [isWaitingOnFlagship, setIsWaitingOnFlagship] = useState<boolean>(true)

  /** For any kind of use-case, conclude isWaitingOnFlagship as false */
  useEffect(() => {
    // by default, we wait on flagship, but one a process has started/concluded don't call this function again
    if (!isWaitingOnFlagship) return
    // as this has dependency on router, this will get called in every route change
    if (!router.isReady) return
    const handleFlagshipUser = async () => {
      if (l2Token) {
        // If L2 token is found, proceed without attempting auto-login
        setIsWaitingOnFlagship(false)
        return
      }

      const flagshipAuthEndpoint = `${publicRuntimeConfig.authMiddleware.endpoint}/flagship/auth`
      let idToken: string | null = null
      let user = undefined

      try {
        idToken = await authFlagshipUserWithL2(flagshipAuthEndpoint)
        user = parseUser(idToken)
      } catch (error) {
        logger.info(error)
        setIsWaitingOnFlagship(false)
        return
      }

      // If no Flagship token is detected from auth-middleware, proceed as usual without attempting auto-login
      if (!idToken || !user) {
        setIsWaitingOnFlagship(false)
        return
      }

      currentUserVar(user)
      // If LumAuth detects a Flagship session cookie and returns a JWT we can set it and auto-login the user
      setCookie(LUMOSITY_USER_COOKIE, idToken, getBrowserCookieOpts())
      logger.info('User continuity: Completed process and redirecting user')
      if (router.pathname.startsWith('/landing')) {
        await router.replace('/')
      }
      setIsWaitingOnFlagship(false)
    }
    handleFlagshipUser()
  }, [isWaitingOnFlagship, l2Token, router, setCookie])
  return { l2Token, isWaitingOnFlagship }
}

export default useFlagshipUser
