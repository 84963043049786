interface IEventAnonymousData {
  userId?: number
  anonymousId: string
}

const EventAnonymousStoreName = 'l2_event_anonymous_data'
const EventAnonymousStore = {
  get: (): Partial<IEventAnonymousData> => {
    const localStorageDatString = localStorage.getItem(EventAnonymousStoreName) || '{}'
    return JSON.parse(localStorageDatString)
  },
  set: (data: IEventAnonymousData) => {
    localStorage.setItem(EventAnonymousStoreName, JSON.stringify(data))
  },
}

export function getEventAnonymousId(user?: LumosUser): IEventAnonymousData['anonymousId'] {
  const storageAnonymousData = EventAnonymousStore.get()
  const anonymousId = storageAnonymousData?.anonymousId ?? undefined
  const userId = storageAnonymousData?.userId ?? undefined
  const currentUserId: number | undefined = user?.id ?? undefined

  if (!anonymousId) {
    // create the anonymous id if it doesn't exists & associate it to the currentUser (currentUser : may or may not exists)
    const newUUID = crypto.randomUUID()
    EventAnonymousStore.set({ anonymousId: newUUID, userId: currentUserId })
    return newUUID
  }

  if (!userId && !currentUserId) {
    // means user is not logged in & the same unknown user is continuing the session, continue with the same anonymous id
    return anonymousId
  }

  if (!userId && currentUserId) {
    // this means user is just now logged in, continue with the same anonymous id
    // associate the anonymous id, from unknown user to the logged in user
    EventAnonymousStore.set({ anonymousId, userId: currentUserId })
    return anonymousId
  }

  if (userId && !currentUserId) {
    // means user is logged out just now, renew the anonymous id & associate it to the unknown user
    const renewUUID = crypto.randomUUID()
    EventAnonymousStore.set({ anonymousId: renewUUID, userId: currentUserId })
    return renewUUID
  }

  if (userId && currentUserId) {
    if (userId === currentUserId) {
      // means user is logged in & the same user is continuing the session, continue with the same anonymous id
      return anonymousId
    } else {
      // means user is logged out & another user is forced logged in, renew the anonymous id & associate it to the new user
      // this can happen if one user was logged in & suddenly another user overrides the auth details,
      // ideally we should not reach here, but if this ever occurs, we should renew the anonymous id
      const renewUUID = crypto.randomUUID()
      EventAnonymousStore.set({ anonymousId: renewUUID, userId: currentUserId })
      return renewUUID
    }
  }

  // ideally we should never reach here
  return anonymousId
}
