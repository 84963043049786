import { useEffect, useState } from 'react'

import { useReactiveVar } from '@apollo/client'
import axios from 'axios'
import getConfig from 'next/config'

import PageLoader from '~/components/ui/PageLoader'
import { ForceLogoutError } from '~/components/ui/sharedStyledComponents'
import { ErrorEventTypes } from '~/events/eventTypes'
import useTrackEvents from '~/events/trackers/useTrackEvents'
import { forceLogoutVar } from '~/graphql/reactive-vars'
import logger from '~/utils/logger'
const { publicRuntimeConfig } = getConfig()

interface ForceLogoutWrapperProps {
  children: React.ReactNode
}

const forceLogoutUrl = `${publicRuntimeConfig.authMiddleware.endpoint}/l2/force-logout`
const ForceLogoutWrapper = ({ children }: ForceLogoutWrapperProps) => {
  const forceLogout = useReactiveVar(forceLogoutVar)
  const [isLoading, setIsLoading] = useState(true)
  const { trackErrorEvent } = useTrackEvents()

  useEffect(() => {
    if (forceLogout) {
      setIsLoading(true)
      axios
        .post(forceLogoutUrl, {}, { withCredentials: true })
        .then(() => {
          forceLogoutVar(false)
        })
        .catch((error) => {
          trackErrorEvent({
            error_type: ErrorEventTypes.ForceLogoutFail,
            error_location: 'Error::ForceLogoutWrapper::ForceLogout',
            error_message: '',
            error_details: error,
          })
          logger.log('Failed to force logout', error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }, [forceLogout, trackErrorEvent])

  if (isLoading) {
    return <PageLoader />
  }

  if (forceLogout) {
    return <ForceLogoutError />
  }

  return <>{children}</>
}

export default ForceLogoutWrapper
