import { Configuration, FrontendApi } from '@ory/kratos-client'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()

let oryFrontendClient: FrontendApi

export function getOryFrontendClient(): FrontendApi {
  if (oryFrontendClient) {
    return oryFrontendClient
  }
  const { authEndpoint } = publicRuntimeConfig.auth.ory
  const configuration = new Configuration({
    basePath: authEndpoint,
    baseOptions: {
      withCredentials: true,
    },
  })

  oryFrontendClient = new FrontendApi(configuration)
  return oryFrontendClient
}
