import { useState } from 'react'

import PageLoader from '~/components/ui/PageLoader'
import { AuthContext } from '~/context/AuthContext'
import useFlagshipUser from '~/hooks/useFlagshipUser'
import { useLocalStorageBackedState } from '~/hooks/useLocalStorageBackedState'
import usePersistedClientConfig from '~/hooks/usePersistedClientValues'

interface AuthProviderProps {
  children: React.ReactNode
  hasPremium?: boolean
  isFreeTrialEligible?: boolean
}

/**
 * Use AuthProvider to handle auth state
 */
const AuthProvider = ({ children, hasPremium = false, isFreeTrialEligible = false }: AuthProviderProps) => {
  const { isWaitingOnFlagship: waitingForFlagshipMigration } = useFlagshipUser()
  const [hasAccountStateOverride, setHasAccountStateOverride] = useLocalStorageBackedState<boolean>(
    'hasAccountStateOverride',
    false,
  )
  const [_hasPremium, setHasPremium] = useLocalStorageBackedState<boolean>('hasPremium', hasPremium)
  const [_isFreeTrialEligible, setIsFreeTrialEligible] = useState<boolean>(isFreeTrialEligible)

  // read local client values and set client state
  usePersistedClientConfig()

  if (waitingForFlagshipMigration) {
    return <PageLoader />
  }

  return (
    <AuthContext.Provider
      value={{
        hasAccountStateOverride,
        setHasAccountStateOverride,
        hasPremium: _hasPremium,
        setHasPremium,
        isFreeTrialEligible: _isFreeTrialEligible,
        setIsFreeTrialEligible,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
