/* eslint-disable no-console */

const getTranslationErrorMessage = (namespace, i18nKey) => {
  if (!i18nKey) {
    // lacking key implies no namespace was provided
    return `[next-translate] The key "${namespace}" lacks a namespace. Please check your translation lookup.`
  }
  return `[next-translate] ${namespace}:${i18nKey} is missing in current namespace configuration. Try adding "${i18nKey}" to the namespace "${namespace}".`
}

const missingKeys = ({ namespace, i18nKey }) => {
  const environment = process.env.NODE_ENV
  const message = getTranslationErrorMessage(namespace, i18nKey)

  // Not using common logger because we're in js here
  // TODO - improve this file/logic in prod.
  // errors should not be logged to rollbar, as #rollbar channel is too noisy with these errors
  // missing keys can be found via - yarn validate-locales, which is run in CI for now.
  if (environment !== 'production') {
    console.error('🚧 [Translation Error]', message)
  }
}

module.exports = { missingKeys, _forTesting: { getTranslationErrorMessage } }
