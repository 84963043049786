import { useCallback } from 'react'

import { UTMSessionStorageName } from '~/constants'
import { useSessionStorageBackedState } from '~/hooks/useSessionStorageBackedState'

export function useUTMSessionStorage() {
  const [utmParams, setUtmParams] = useSessionStorageBackedState<Record<string, string> | null>(
    UTMSessionStorageName,
    null,
  )

  const setUTMFromQueryParams = useCallback(
    (queryParams: URLSearchParams) => {
      const utmObject: Record<string, string> = {}
      queryParams.forEach((value, utmObjectKey) => {
        if (utmObjectKey.startsWith('utm_')) {
          utmObject[utmObjectKey] = value
        }
      })

      // setUTMFromQueryParams can be a dependency of useEffect,
      // when utmParams is set inside this function, both - utmParams, setUtmParams will change because of useSessionStorageBackedState,
      // to prevent infinite loop, always check if the old utm is not same as new utm
      if (JSON.stringify(utmObject) === JSON.stringify(utmParams)) {
        return
      }

      if (Object.keys(utmObject).length > 0) {
        setUtmParams(utmObject)
      }
    },
    [setUtmParams, utmParams],
  )
  return { utmParams, setUtmParams, setUTMFromQueryParams }
}
