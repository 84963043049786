// Import useSnackbar from 'notistack' only in this file. This file contains a custom wrapper for the default notistack hook.
import {
  OptionsWithExtraProps,
  SnackbarMessage,
  VariantType,
  // eslint-disable-next-line no-restricted-imports
  useSnackbar as useSnackbarDefault,
} from 'notistack'

type ReturnSnackbarType = ReturnType<typeof useSnackbarDefault>

/** useSnackbar is a custom hook that wraps the default notistack hook to track error messages
 * This is used to track events,
 * This can also be used to populate default values for the snackbar
 */
export const useSnackbar = (): ReturnSnackbarType => {
  const { enqueueSnackbar: enqueueSnackbarDefault, ...rest } = useSnackbarDefault()

  const enqueueSnackbar = <V extends VariantType>(
    message: SnackbarMessage,
    // we don't want to allow content in options, because its deprecated
    options?: Omit<OptionsWithExtraProps<V>, 'content'>,
  ) => {
    return enqueueSnackbarDefault(message, options as OptionsWithExtraProps<V>)
  }

  return {
    enqueueSnackbar,
    ...rest,
  }
}
