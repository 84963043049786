import { useEffect, useMemo } from 'react'

import { useReactiveVar } from '@apollo/client'
import { useRouter } from 'next/router'

import { PUBLIC_PATHS } from '~/constants'
import { useLogout } from '~/hooks/useAuth'
import { useUTMSessionStorage } from '~/hooks/useUtmSessionStorage'
import { currentUserVar } from '~/reactive-vars'

export interface RouteGuardProps {
  children: JSX.Element
}

const RouteGuard = ({ children }: RouteGuardProps): JSX.Element => {
  const { processOryLogout } = useLogout()

  const currentUser = useReactiveVar(currentUserVar)
  const router = useRouter()
  const currentPath = router.pathname
  const { query } = router
  const queryParams = useMemo(() => new URLSearchParams(query as Record<string, string>), [query])
  const queryString = queryParams.toString()
  const isPublicPath = PUBLIC_PATHS.some((path) => currentPath.startsWith(path))
  const isSessionValid = currentUser && currentUser.authExpiration >= Date.now()
  const isAuthorized = isPublicPath || isSessionValid
  const { setUTMFromQueryParams } = useUTMSessionStorage()

  useEffect(() => {
    setUTMFromQueryParams(queryParams)
  }, [queryParams, setUTMFromQueryParams])

  if (!isSessionValid && currentUser) {
    // SAFETY CHECK: by any chance, if user session if invalid, but cookie/user still exists
    // remove lumosity user cookie & do logout related clean ups
    processOryLogout()
  }

  if (!isAuthorized && currentPath !== '/landing') {
    router.push({
      pathname: '/landing',
      // if there is query string, then pass it to landing page
      ...(queryString && { query: queryString }),
    })
    return <></>
  }

  if (!isAuthorized) {
    return <></>
  }

  return children
}

export default RouteGuard
