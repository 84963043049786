import { forwardRef } from 'react'

import { BodyText2 } from '@lumoslabs/lumosity-storybook'
import { SnackbarContent, SnackbarKey, SnackbarMessage } from 'notistack'
import styled from 'styled-components'

import { CloseNotificationButton } from '~/components/ui/sharedStyledComponents'
import { useSnackbar } from '~/libs/notistack'

interface SuccessSnackbarProps {
  id: SnackbarKey
  message: SnackbarMessage
}

const SuccessSnackbar = forwardRef<HTMLDivElement, SuccessSnackbarProps>(({ message, id }, ref) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <SnackbarContent ref={ref} id={id as string} style={{ display: 'grid' }}>
      <SnackbarContainer>
        <Title>{message}</Title>
        <CloseNotificationButton title='close-message' onClick={() => closeSnackbar()} />
      </SnackbarContainer>
    </SnackbarContent>
  )
})

const SnackbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background: ${({ theme }) => theme.colorTokens.surface['surface-success']};
  border: ${({ theme }) => `1px solid ${theme.colorTokens.border['border-success']}}`};
  padding: 24px;
  max-width: 360px;
  gap: 16px;
`

const Title = styled(BodyText2)`
  word-break: break-word;
  flex: 1;
`

export default SuccessSnackbar
