import { PlayContext } from '~/constants'

export const getEventAppName = (isMobile: boolean) => (isMobile ? 'lumosity2_mweb' : 'lumosity2_web')

export const EventName = {
  PageView: 'screen_view',
  PopUpView: 'pop_up_view',
  Click: 'object_click',
  SignOut: 'sign_out',
  GameStart: 'game_start',
  GameAbort: 'game_abort',
  GameFinish: 'game_finish',
  GameOutput: 'game_output',
  GameCrash: 'game_crash',
  WorkoutStart: 'workout_start',
  WorkoutFinish: 'workout_finish',
  WorkoutSwap: 'workout_game_swap',
  EmailTrainingRemindersSelect: 'email_training_reminders_select',
  ErrorDisplay: 'error_display',
  ErrorOccur: 'error_occur',
  UnsupportedBrowserError: 'unsupported_browser_error',
} as const
export type EventNameValue = ValueOf<typeof EventName>

export const EventScreenName = {
  IntroScreen: 'intro_screen',
  Login: 'login',
  ResetPassword: 'reset_password',
  ResetPasswordEmailSent: 'reset_password_email_sent',
  CreateAccount: 'create_account',
  AuthGenericError: 'auth_generic_error',
  OnboardingStart: 'onboarding_start',
  OnboardingGender: 'onboarding_gender',
  OnboardingEducation: 'onboarding_education',
  OnboardingProfession: 'onboarding_profession',
  OnboardingReferral: 'onboarding_referral',
  FitTestStart: 'fit_test_start',
  FitTestPercentile: 'fit_test_percentile',
  FitTestScience: 'fit_test_science',
  Score: 'score',
  Streak: 'streak',
  Reminder: 'email_training_reminders',
  Paywall: 'paywall',
  BadgeUnlock: 'badge_unlock',
  PlanSelection: 'plan_selection',
  PaymentMethod: 'payment_method',
  Checkout: 'checkout',
  Home: 'home',
  Game: 'game',
  Stats: 'stats',
  Settings: 'settings',
  Pregame: 'pregame',
  GamePlayer: 'game_player',
  PostGame: 'postgame',
  WorkoutComplete: 'workout_complete',
  PaymentConfirmation: 'payment_confirmation',
  AccountBirthDate: 'account_birthdate',
  UnlockPremiumMidWorkout: 'mid_workout_premium_upsell',
  BillingInformation: 'billing_information',
  LogOut: 'log_out',
  GameLocked: 'game_locked',
  WorkoutSwap: 'workout_swap',
  NotificationsDisabled: 'notifications_disabled',
  TermsOfService: 'terms_of_service',
  PrivacyPolicy: 'privacy_policy',
  CaliforniaPrivacy: 'california_privacy',
  ForgotPassword: 'forgot_password',
  ForgotPasswordPasscode: 'forgot_password_passcode',
  CreateAccountEligibilityMessage: 'create_account_eligibility_message',
  FamilyPlanRemovalConfirmation: 'family_plan_removal_confirmation',
  FamilyPlanManagement: 'family_plan_management',

  /** Below are external Pages, like stripe, helpcenter */
  HelpCenter: 'help_center',
  StripeCheckout: 'stripe_checkout',
  ReminderConfirmation: 'reminder_confirmation',
} as const

export type EventScreenNameValues = ValueOf<typeof EventScreenName>

/**
 * As same component can be used in multiple places,
 * we need to track the context of the game
 */
export const EventGameContext = {
  Games: 'games',
  Workout: 'workout',
  FitTest: 'fittest',
} as const

export type EventGameContextValues = ValueOf<typeof EventGameContext>

/**
 * Why PlayContext to EventGameContext map is needed?
 * PlayContext represents code Enum
 * EventGameContext represents what we need to sent to the events
 */
export const PlayContextToGameContextMap = {
  [PlayContext.Workout]: EventGameContext.Workout,
  [PlayContext.FitTest]: EventGameContext.FitTest,
  [PlayContext.FreePlay]: EventGameContext.Games,
} as const

export const EventClick = {
  types: {
    Button: 'button',
    Banner: 'banner',
    Link: 'link',
    SingleSelect: 'single_select',
  },
  names: {
    Subscribe: 'subscribe',
    PaymentMethodCard: 'payment_method_card',
    PaymentMethodPaypal: 'payment_method_paypal',
    PlanSelectionWebMonthly: 'plan_selection_web_monthly',
    PlanSelectionWebYearly: 'plan_selection_web_yearly',
    PlanSelectionWebBiennially: 'plan_selection_web_biennially',
    PlanSelectionWebLifetime: 'plan_selection_web_lifetime',
    PlanSelectionWebPlanTypeToggle: 'plan_selection_web_plan_type_toggle',
    PaymentConfirmationStartTraining: 'payment_confirmation_start_training',
    HomeWorkoutPlay: 'home_workout_play',
    WorkoutCompleteAcknowledge: 'workout_complete_acknowledge',
    PlayWorkout: 'play_workout',
    PregameWorkoutGameSwap: 'pregame_workout_game_swap',
    MidWorkoutPremiumUpsellSubscribe: 'mid_workout_premium_upsell_subscribe',
    MidWorkoutPremiumUpsellSkip: 'mid_workout_premium_upsell_skip',
    PregamePlay: 'pregame_play',
    PregameHowToPlay: 'pregame_how_to_play',
    PostGameContinue: 'postgame_continue',
    WorkoutComplete: 'workout_complete',
    LoginEmailPassword: 'login_email_password',
    LoginGoogle: 'login_google',
    LoginFacebook: 'login_facebook',
    LoginApple: 'login_apple',
    CreateAccountEmailSignup: 'create_account_email_signup',
    CreateAccountGoogle: 'create_account_google',
    CreateAccountFacebook: 'create_account_facebook',
    CreateAccountApple: 'create_account_apple',
    OnboardBegin: 'onboard_begin',
    OnboardGenderSkip: 'onboard_gender_skip',
    OnboardGenderBack: 'onboard_gender_back',
    OnboardGenderNext: 'onboard_gender_next',
    OnboardEducationSkip: 'onboard_education_skip',
    OnboardEducationBack: 'onboard_education_back',
    OnboardEducationNext: 'onboard_education_next',
    OnboardProfessionSkip: 'onboard_profession_skip',
    OnboardProfessionBack: 'onboard_profession_back',
    OnboardProfessionNext: 'onboard_profession_next',
    OnboardReferralSkip: 'onboard_referral_skip',
    OnboardReferralBack: 'onboard_referral_back',
    OnboardReferralNext: 'onboard_referral_next',
    OnboardPregameNext: 'onboard_pregame_next',
    OnboardPregameBack: 'onboard_pregame_back',
    FitTestBegin: 'fit_test_begin',
    PlayFitTest: 'play_fit_test',
    FitTestWelcomeNext: 'fit_test_welcome_next',
    FitTestPercentileNext: 'fit_test_percentile_next',
    FitTestScienceNext: 'fit_test_science_next',
    StreakAcknowledgement: 'streak_acknowledgement',
    BadgeUnlockAcknowledgement: 'badge_unlock_acknowledgement',
    EmailTrainingReminderSet: 'email_training_reminder_set',
    EmailTrainingReminderLater: 'email_training_reminder_later',
    Home: 'home',
    Games: 'games',
    Stats: 'stats',
    Settings: 'settings',
    GamePlayerPlay: 'game_player_play',
    GamePlayerHowTo: 'game_player_how_to',
    GameLockedClose: 'game_locked_close',
    AccountBirthdayContinue: 'account_birthdate_continue',
    WorkoutUpsellLearnAboutPremium: 'workout_upsell_learn_about_premium',
    WorkoutUpsellSubscribe: 'workout_upsell_subscribe',
    WorkoutUpsellBack: 'workout_upsell_back',
    PaymentMethodManage: 'payment_method_manage',
    PaymentMethodAutoRenewal: 'payment_method_auto_renewal',
    WorkoutSwap: 'workout_swap',
    VisitHelpdesk: 'visit_helpdesk',
    ManageAutorenewingSubscription: 'manage_autorenewing_subscription',
    TurnOnAutorenewingSubscription: 'turn_on_autorenewing_subscription',
    TurnOffAutorenewingSubscription: 'turn_off_autorenewing_subscription',
    CancelTurnOffAutorenewingSubscription: 'cancel_turn_off_autorenewing_subscription',
    ContactUs: 'contact_us',
    PlayNewestGame: 'play_newest_game',
    TurnOnTrainingReminders: 'turn_on_training_reminders',
    ContactCustomerSupport: 'contact_customer_support',
    PrivacyPolicy: 'privacy_policy',
    CaliforniaPrivacy: 'california_privacy',
    TermsOfService: 'terms_of_service',
    LogOut: 'log_out',
    LogOutConfirm: 'log_out_confirm',
    LogOutCancel: 'log_out_cancel',
    GoToGames: 'go_to_games',
    LearnAboutPremium: 'learn_about_premium',
    LoginForgotPassword: 'login_forgot_password',
    ForgotPasswordSubmitEmail: 'forgot_password_submit_email',
    ForgotPasswordGoBack: 'forgot_password_go_back',
    ForgotPasswordSubmitPasscode: 'forgot_password_submit_passcode',
    ForgotPasswordResendCode: 'forgot_password_resend_code',
    PaymentConfirmationAcknowledge: 'payment_confirmation_acknowledge',
    BillingInformationPaymentMethodManage: 'billing_information_payment_method_manage',
    BillingInformationAutoRenewalManage: 'billing_information_auto_renewal_manage',
    CancelFreeTrial: 'cancel_free_trial',
    PaywallChooseAPlan: 'paywall_choose_a_plan',
    PaywallContinuewWithLimited: 'paywall_continue_with_limited',
    PostGamePlayAgain: 'postgame_play_again',
  },
} as const

export type EventClickTypeValues = ValueOf<typeof EventClick.types>
export type EventClickNamesValues = ValueOf<typeof EventClick.names>

export const ErrorEventTypes = {
  AccountRegisterFail: 'account_register_fail',
  SignInFail: 'sign_in_fail',
  LogOutFail: 'log_out_fail',
  ForceLogoutFail: 'force_log_out_fail',
  AccountRecoveryFail: 'account_recovery_fail',
  DobUpdateFail: 'dob_update_fail',
  AccessTokenToJwtExchangeFail: 'access_token_to_jwt_exchange_fail',
} as const

export type ErrorEventTypesValues = ValueOf<typeof ErrorEventTypes>

export type CommonGameEventProps = {
  game_slug?: string
  game_session_id?: string
  workout_id?: string
  is_swapped?: boolean
  is_replay?: boolean
  game_context?: EventGameContextValues
}
