/* eslint-disable no-console */
import logger from '~/utils/logger'

export const logToRollbar = (...args: (string | Error | object)[]) => {
  if (typeof window !== 'undefined') {
    if (process.env.NODE_ENV === 'production' && args !== null) {
      window?.Rollbar?.error?.(...args, (rollbarError) => {
        if (rollbarError) {
          console.error('Rollbar error reporting failed:')
          console.error(rollbarError)
          return
        }
        logger.info('Reported error to Rollbar')
      })
    }
  }
}
