import { forwardRef, useRef } from 'react'

import { BodyText2 } from '@lumoslabs/lumosity-storybook'
import { SnackbarContent, SnackbarKey, SnackbarMessage } from 'notistack'
import styled from 'styled-components'

import { CloseNotificationButton } from '~/components/ui/sharedStyledComponents'
import useTrackEvents from '~/events/trackers/useTrackEvents'
import { useSnackbar } from '~/libs/notistack'

interface ErrorSnackbarProps {
  id: SnackbarKey
  message: SnackbarMessage
}

const ErrorSnackbar = forwardRef<HTMLDivElement, ErrorSnackbarProps>(({ message, id }, ref) => {
  const didSendEvent = useRef<boolean>(false)
  const { closeSnackbar } = useSnackbar()
  const { trackErrorDisplay } = useTrackEvents()

  const onChangeTitleRef = (contentRef: HTMLSpanElement | null) => {
    if (contentRef && !didSendEvent.current) {
      didSendEvent.current = true
      trackErrorDisplay({ errorMessage: contentRef.innerText ?? '' })
    }
  }

  return (
    <SnackbarContent ref={ref} id={id as string} style={{ display: 'grid' }}>
      <SnackbarContainer>
        <Title ref={(contentRef) => onChangeTitleRef(contentRef)}>{message}</Title>
        <CloseNotificationButton title='close-message' onClick={() => closeSnackbar()} />
      </SnackbarContainer>
    </SnackbarContent>
  )
})

const SnackbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background: ${({ theme }) => theme.colorTokens.surface['surface-alert']};
  border: ${({ theme }) => `1px solid ${theme.colorTokens.border['border-alert']}}`};
  padding: 24px;
  max-width: 360px;
  gap: 16px;
`

const Title = styled(BodyText2)`
  word-break: break-word;
  flex: 1;
`

export default ErrorSnackbar
