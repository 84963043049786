import { useUserAgentInfo } from '~/hooks/useUserAgentInfo'

const useIsMobileDevice = () => {
  const { uaResults } = useUserAgentInfo()
  const isMobile = uaResults.device.type === 'mobile'
  const isTablet = uaResults.device.type === 'tablet'

  return isMobile || isTablet
}

export default useIsMobileDevice
