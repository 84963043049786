import { createContext } from 'react'

import type { User, Card } from '@braze/web-sdk'

// Braze Event Tracking

export interface MarketingContext {
  contentCards: Card[]
  setContentCards: (cards: Card[]) => void
  marketingUser: User | null
  handleBrazeAction: (url: string, openLinkInNewTab?: boolean) => void
  logCustomMarketingEvent: (eventName: string, eventProperties?: object | undefined) => boolean
  logCardClickEvent: (card: any, forContentCards?: boolean) => boolean
  logCardImpressionEvent: (card: any, forContentCards?: boolean) => boolean
  logCardDismissalEvent: (card: any) => boolean
  logMarketingWorkoutComplete: (gameSlugs: GameSlug[]) => boolean
  logMarketingFitTestStart: () => boolean
  logMarketingFitTestFinish: () => boolean
  logMarketingGameStart: (gameSlug: GameSlug) => boolean
  logMarketingGameFinish: (gameSlug: GameSlug, score: number, areaSlug: AreaSlug | '') => boolean
}

export const MarketingContext = createContext<MarketingContext>({
  contentCards: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setContentCards: (_: Card[]) => {},
  marketingUser: null,
  handleBrazeAction: () => {},
  logCustomMarketingEvent: () => false,
  logCardClickEvent: () => false,
  logCardImpressionEvent: () => false,
  logCardDismissalEvent: () => false,
  logMarketingWorkoutComplete: () => false,
  logMarketingFitTestStart: () => false,
  logMarketingFitTestFinish: () => false,
  logMarketingGameStart: () => false,
  logMarketingGameFinish: () => false,
})
